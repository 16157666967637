// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import Example from "https://framer.com/m/framer/Example.js@^1.0.0"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function FormSpark(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <form
            // here you have to paste your individual link from f.e. formspark
            action="https://submit-form.com/aRYRfh4P"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                fontFamily: "Inter, sans-serif",
                fontSize: "18px",
                color: "black",
                padding: "24px",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.0)",
            }}
        >
            <input
                type="hidden"
                name="_redirect"
                // here you have to paste link, where do you want to lead your users to
                value="Paste *REDIRECTION* Link Here"
            />
            <label for="name" style={{ marginBottom: "8px" }}>
                Name:
            </label>
            <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label for="email" style={{ marginBottom: "8px" }}>
                Email:
            </label>
            <input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label for="propertyaddress" style={{ marginBottom: "8px" }}>
                Property Address:
            </label>
            <input
                type="text"
                id="propertyaddress"
                name="address"
                placeholder="Address"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label for="message" style={{ marginBottom: "8px" }}>
                Message:
            </label>
            <textarea
                type="textarea"
                rows="4"
                id="message"
                name="message"
                placeholder="Message"
                required
                style={{
                    width: "100%",
                    padding: "16px",
                    marginBottom: "24px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <label style={{ marginBottom: "24px" }}>
                <input
                    type="checkbox"
                    required
                    style={{ marginRight: "8px" }}
                />
                I am not a robot.
            </label>
            <input
                type="submit"
                value="Submit"
                style={{
                    fontSize: "16px",
                    fontFamily: "Manrope, sans serif",
                    width: "100%",
                    padding: "16px",
                    backgroundColor: "#008ABD",
                    color: "white",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
            />
        </form>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
